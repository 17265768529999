import { ContextModalProps } from "@mantine/modals";
import { ConfirmNewPlanProps } from "types/modals";
import TitleWithClose from "../components/TitleWithClose";
import { usePostUpgradeShopifyContractMutation, useGetCompanyQuery } from "app/services/rolebot";
import { Group, Text, Divider, Button, MediaQuery, createStyles } from "@mantine/core";
import moment from "moment";
import { useState } from "react";
import { toastError, toastSuccess } from "utils/toastify-messages";

const useStyles = createStyles((theme) => ({
    button: {
        flexGrow: 1,
        [theme.fn.largerThan('md')]: {
            flexGrow: 0,
        },
    },
}));

const ConfirmNewPlan = ({ id, context, innerProps }: ContextModalProps<ConfirmNewPlanProps>) => {
    const { activeRoleLimit, productPrice, customerPlanName, proratedSurcharge, nextBillingDate, shopifyContractId, productId, sellingPlanId } = innerProps;
    const [postUpgradeShopifyContract, { isLoading, isSuccess, isError, reset }] = usePostUpgradeShopifyContractMutation();
    const { data: company } = useGetCompanyQuery();
    const { classes } = useStyles();

    const handleCloseModal = () => {
        context.closeModal(id);
    }

    console.log('innerProps', innerProps);

    const handleSubmitPurchase = async () => {
        try {
            await postUpgradeShopifyContract({
                customerId: company?.id,
                payload: {
                    shopify_contract_id: shopifyContractId,
                    surcharge: proratedSurcharge,
                    selling_plan_id: sellingPlanId,
                    product_id: productId,
                    product_price: productPrice,
                    quantity: activeRoleLimit
                }
            }).unwrap();

            context.closeModal(id);

            toastSuccess(
                <div className="toastContainer">
                    <div>Success! Your plan has been upgraded</div>
                    <div>Thank you for confirming your plan update. You can now start enjoying your new plan, which will take effect immediately.</div>
                </div>
            );

        } catch (e: any) {
            console.log('updgradePlan error', e);
            toastError(e.data.message);
        }
    }

    return (
        <>
            <TitleWithClose id={id} title="Back to all plans" mb={20} />
            <Text mb={10} style={{ fontSize: '22px', fontWeight: 500, color: '#242424', fontFamily: 'Roboto, sans-serif' }}>
                Your new plan
            </Text>
            <Text mb={20} style={{ fontSize: '16px', fontWeight: 400, color: '#242424', fontFamily: 'Helvetica, sans-serif' }}>
                Your new plan will come into effect as soon as you submit your changes. The updated amount will be charged on your upcoming billing date. Please review the details below and confirm to finalize your upgrade.
            </Text>
            <Group position="apart">
                <Text style={{ fontSize: '16px', fontWeight: 500, color: '#242424', fontFamily: 'Roboto, sans-serif' }}>
                    {customerPlanName} plan
                </Text>
                <Text style={{ fontSize: '16px', fontWeight: 500, color: '#242424', fontFamily: 'Roboto, sans-serif' }}>
                    ${productPrice * activeRoleLimit}<span style={{ fontFamily: 'Helvetica, sans-serif', fontWeight: 400 }}> / month</span>
                </Text>
            </Group>
            <Text mb={20} style={{ fontSize: '16px', fontWeight: 400, color: '#242424', fontFamily: 'Helvetica, sans-serif' }}>
                {activeRoleLimit} Active role limit
            </Text>
            <Group position="apart">
                <Text style={{ fontSize: '16px', fontWeight: 500, color: '#242424', fontFamily: 'Roboto, sans-serif' }}>
                    Prorated surcharge
                </Text>
                <Text style={{ fontSize: '16px', fontWeight: 500, color: '#242424', fontFamily: 'Roboto, sans-serif' }}>
                    ${proratedSurcharge}
                </Text>
            </Group>
            <Text mb={20} style={{ fontSize: '16px', fontWeight: 400, color: '#242424', fontFamily: 'Helvetica, sans-serif', width: '50%' }}>
                Based on the price difference and remaining days before the renewal date
            </Text>
            <Divider mb={20} color="#DFE1E1" />
            <Group mb={20} position="apart">
                <Text style={{ fontSize: '16px', fontWeight: 500, color: '#242424', fontFamily: 'Roboto, sans-serif' }}>
                    Total
                </Text>
                <Text style={{ fontSize: '16px', fontWeight: 500, color: '#242424', fontFamily: 'Roboto, sans-serif' }}>
                    ${productPrice * activeRoleLimit + proratedSurcharge}
                </Text>
            </Group>
            <Text mb={40} style={{ fontSize: '16px', fontWeight: 400, color: '#242424', fontFamily: 'Helvetica, sans-serif' }}>
                Next charge date: {moment(nextBillingDate).format('D MMMM, YYYY')}
            </Text>
            <Group position={'right'} mt={20} align={'end'}>
                <MediaQuery styles={{ display: 'none' }} smallerThan={'md'}>
                    <Button disabled={isLoading} onClick={handleCloseModal} variant={'outline'}>
                        Cancel
                    </Button>
                </MediaQuery>
                <Button
                    onClick={handleSubmitPurchase}
                    loading={isLoading}
                    loaderPosition={'right'}
                    className={classes.button}
                >
                    Submit purchase
                </Button>
            </Group>
        </>
    )
}

export default ConfirmNewPlan;