import { ContextModalProps } from "@mantine/modals"
import { Text, LoadingOverlay } from "@mantine/core"
import { UpgradePlanProps } from "types/modals"
import { createStyles } from "@mantine/core";
import useOpenModal from 'hooks/useOpenModal';
import { useModals } from '@mantine/modals';
import TitleWithClose from "../components/TitleWithClose";
import { useState, useMemo } from "react";
import { useGetShopifyProductsQuery, useGetCompanyQuery } from "app/services/rolebot";
import RolebotPlan from "./RolebotPlan";
import PlanComparisonTable from "./PlanComparisonTable";
import BillingCycleToggle from "./BillingCycleToggle";

const useStyles = createStyles((theme) => ({
    container: {
        padding: '20px',
        background: 'linear-gradient(180deg, #48309D 26%, #24154F 100%)',
        paddingBottom: '60px',
    }
}));

const UpgradePlan = ({ id, context, innerProps }: ContextModalProps<UpgradePlanProps>) => {
    const { classes, cx } = useStyles();

    const modals = useModals();
    const { confirmNewPlanModal } = useOpenModal();
    const [billingCycle, setBillingCycle] = useState('monthly');
    const { data: shopifyProducts, isFetching } = useGetShopifyProductsQuery({});
    const { data: company } = useGetCompanyQuery();

    const activeRoleLimit = useMemo(() => {
        return company?.active_role_limit;
    }, [company]);

    const handleBillingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBillingCycle(event.target.value);
    };

    const handlePlanSelected = (plan: any) => {
        modals.closeModal(id);
        confirmNewPlanModal({
            activeRoleLimit: plan.activeRoleLimit,
            productPrice: plan.productPrice,
            customerPlanName: company?.customer_pricing_plan?.pricing_plan?.name,
            // proratedSurcharge: plan.productPrice - company?.customer_pricing_plan?.pricing_plan?.amount
            proratedSurcharge: 400,
            nextBillingDate: company?.customer_pricing_plan?.next_billing_date,
            shopifyContractId: company?.customer_pricing_plan?.shopify_contract_id,
            sellingPlanId: plan.sellingPlanId,
            productId: plan.productId
        });
    };

    const handleContactSales = () => {
        console.log('Contact sales');
    };

    return (
        <>
            <div className={!isFetching ? classes.container : ''}>
                <LoadingOverlay visible={isFetching} />
                {!isFetching && (
                    <>
                        <TitleWithClose id={id} title={''} mb={4} />
                        <Text align="center" mb={20} style={{ fontFamily: 'Helvetica, sans-serif', fontSize: '40px', fontWeight: 400, color: '#FFFFFF' }}>
                            Make changes to your plan
                        </Text>
                        <Text align="center" style={{ fontFamily: 'Helvetica, sans-serif', fontSize: '19px', fontWeight: 400, color: '#FFFFFF' }}>
                            Select the number of roles you'd like to have (up to 5).
                        </Text>
                        <Text align="center" mb={40} style={{ fontFamily: 'Helvetica, sans-serif', fontSize: '19px', fontWeight: 400, color: '#FFFFFF' }}>
                            For more than 5 roles, reach out to our sales team for a tailored solution.
                        </Text>
                        <BillingCycleToggle
                            billingCycle={billingCycle}
                            handleBillingChange={handleBillingChange}
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '40px',
                            }}
                        >
                            {shopifyProducts?.data && shopifyProducts.data.map((product: any) => (
                                <RolebotPlan
                                    key={product.id}
                                    product={product}
                                    isPro={false}
                                    billingCycle={billingCycle}
                                    handlePlanSelected={handlePlanSelected}
                                    handleContactSales={handleContactSales}
                                    activeRoleLimit={activeRoleLimit}
                                />
                            ))}
                            <RolebotPlan
                                key="pro-plan"
                                product={{ title: 'Pro' }}
                                isPro={true}
                                billingCycle={billingCycle}
                                handlePlanSelected={handlePlanSelected}
                                handleContactSales={handleContactSales}
                                activeRoleLimit={activeRoleLimit}
                            />
                        </div>
                    </>
                )}
            </div>
            {!isFetching && <PlanComparisonTable />}
        </>
    )
}

export default UpgradePlan